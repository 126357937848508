import React from "react"
import Styles from "./css/chunkyfooter.module.scss"
import footerData from "./data/chunkyfooter.json"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class ChunkyFooter extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: footerData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (
      
      <div id = {Styles.chunkyFooter}>
        <h3 className = {Styles.footerHeadline}>{this.state.data.footerHeadline}</h3>
        <ul className = {Styles.footerList}>
              {this.state.data.footerList != undefined ? this.state.data.footerList.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
        </ul>
        <img className = {Styles.footerImg} src={this.state.data.footerImage} />
        <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.footerButton} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.footerButton }}></span></button>
        {this.props.children}
      </div>
    )
  }
}
export default ChunkyFooter
