import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Fflp from "src/components/fflp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Disclaimers from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
const chromelpezData = import("./data/data.json");
const footerData = import("./data/footerData.json");


export default function spachroma() {

  return(
    <HomepageLayout>
    <Helmet>

     <style type="text/css">{`
#fflp-module--fflp .fflp-module--main {
min-height:100vh;}
     
      #disclaimers-module--disclaimers {
      width:100%;
      }

     #disclaimers-module--disclaimers p {
      font-weight: 300;
      color: #333;
      font-size: 13px;
      text-align: center;
      font-family: Roboto,sans-serif;
      max-width: 570px;
      margin: 0 auto;
      }

      #disclaimers-module--disclaimers p a {
      font-weight:700;
      }

.footerlinks-module--br {
display:none !important; }


      footer {
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 0 0 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Fflp data={chromelpezData}>
      <Disclaimers language='fr-chrome'></Disclaimers>
      </Fflp>
      </section>
      <ChunkyFooter data={footerData}><Disclaimers language='fr-chrome'></Disclaimers></ChunkyFooter>
      <RawFooter language='fr'></RawFooter>
    </HomepageLayout>
  )
}